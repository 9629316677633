import React from "react";
interface Props {}

interface State {}

class Solutions extends React.Component<Props, State> {
  state = {};
  render() {
    return (
      <section className="container mb-5 pb-lg-5 pb-md-4 pb-3">
        <h2 className="h1 mb-lg-5 mb-4 pb-lg-0 pb-md-2 text-center">
          Custom Software Solutions
        </h2>
        {/* Swiper slider */}
        <div
          className="swiper mb-xl-3"
          data-swiper-options='{
                    "spaceBetween": 24,
                    "breakpoints": {
                      "0": {
                        "slidesPerView": 1
                      },
                      "500": {
                        "slidesPerView": 2
                      },
                      "768": {
                        "slidesPerView": 3
                      },
                      "1200": {
                        "slidesPerView": 4
                      }
                    },
                    "pagination": {
                      "el": ".swiper-pagination",
                      "clickable": true
                    }
                  }'
        >
          <div className="swiper-wrapper">
            {/* Swiper item */}
            <div className="swiper-slide h-auto">
              <div className="card h-100">
                <div className="card-body my-md-5 my-4 py-xl-2 py-md-0 py-sm-2 text-center">
                  <img
                    src="assets/img/landing/software-agency-3/icons/01.svg"
                    alt="Icon"
                    className="d-block mb-3 mx-auto"
                  />
                  <h3 className="h5 mb-3">Software Development</h3>
                  <p className="mb-0">
                    Custom backend to be used on the frontend site.
                  </p>
                </div>
              </div>
            </div>
            {/* Swiper item */}
            <div className="swiper-slide h-auto">
              <div className="card h-100">
                <div className="card-body my-md-5 my-4 py-xl-2 py-md-0 py-sm-2 text-center">
                  <img
                    src="assets/img/landing/software-agency-3/icons/02.svg"
                    alt="Icon"
                    className="d-block mb-3 mx-auto"
                  />
                  <h3 className="h5 mb-3">Website Development</h3>
                  <p className="mb-0">
                    Creating a UI website to showcase anything you want.
                  </p>
                </div>
              </div>
            </div>
            {/* Swiper item */}
            <div className="swiper-slide h-auto">
              <div className="card h-100">
                <div className="card-body my-md-5 my-4 py-xl-2 py-md-0 py-sm-2 text-center">
                  <img
                    src="assets/img/landing/software-agency-3/icons/03.svg"
                    alt="Icon"
                    className="d-block mb-3 mx-auto"
                  />
                  <h3 className="h5 mb-3">Support &amp; Maintenance</h3>
                  <p className="mb-0">
                    Speedy replys on support and multiple revisions.
                  </p>
                </div>
              </div>
            </div>
            {/* Swiper item */}
            {/* <div className="swiper-slide h-auto">
              <div className="card h-100">
                <div className="card-body my-md-5 my-4 py-xl-2 py-md-0 py-sm-2 text-center">
                  <img
                    src="assets/img/landing/software-agency-3/icons/04.svg"
                    alt="Icon"
                    className="d-block mb-3 mx-auto"
                  />
                  <h3 className="h5 mb-3">Software QA &amp; Testing</h3>
                  <p className="mb-0">
                    Amet felis viverra proin feugiat. Eget metus metus lorem
                    dolor pellentesque.
                  </p>
                </div>
              </div>
            </div> */}
          </div>
          {/* Swiper pagination */}
          <div className="swiper-pagination position-static mt-4 pt-lg-3 pt-2" />
        </div>
      </section>
    );
  }
}

export default Solutions;
