import React from "react";
interface TechnologiesProps {}

interface TechnologiesState {}

class Technologies extends React.Component<
  TechnologiesProps,
  TechnologiesState
> {
  state = {};
  render() {
    return (
      <section className="container mb-5 pb-lg-5 pb-md-4 pb-3">
        {/* Swiper slider */}
        <div className="swiper mb-xl-3 text-center">
          <div className="swiper-wrapper">
            {/* Swiper item */}
            <div className="swiper-slide">
              <div className="mb-3">
                <img
                  src="assets/img/landing/software-agency-3/technologies/js-light.svg"
                  alt="Logo"
                  className="d-dark-mode-none d-inline-block"
                />
                <img
                  src="assets/img/landing/software-agency-3/technologies/js-dark.svg"
                  alt="Logo"
                  className="d-dark-mode-inline-block d-none"
                />
              </div>
              <span className="text-body">Javascript</span>
            </div>
            {/* Swiper item */}
            <div className="swiper-slide">
              <div className="mb-3">
                <img
                  src="assets/img/landing/software-agency-3/technologies/node-light.svg"
                  alt="Logo"
                  className="d-dark-mode-none d-inline-block"
                />
                <img
                  src="assets/img/landing/software-agency-3/technologies/node-dark.svg"
                  alt="Logo"
                  className="d-dark-mode-inline-block d-none"
                />
              </div>
              <span className="text-body">Node.JS</span>
            </div>
            {/* Swiper item */}
            <div className="swiper-slide">
              <div className="mb-3">
                <img
                  src="assets/img/landing/software-agency-3/technologies/react-light.svg"
                  alt="Logo"
                  className="d-dark-mode-none d-inline-block"
                />
                <img
                  src="assets/img/landing/software-agency-3/technologies/react-dark.svg"
                  alt="Logo"
                  className="d-dark-mode-inline-block d-none"
                />
              </div>
              <span className="text-body">React</span>
            </div>
            {/* Swiper item */}
            {/* <div className="swiper-slide">
              <div className="mb-3">
                <img
                  src="assets/img/landing/software-agency-3/technologies/php-light.svg"
                  alt="Logo"
                  className="d-dark-mode-none d-inline-block"
                />
                <img
                  src="assets/img/landing/software-agency-3/technologies/php-dark.svg"
                  alt="Logo"
                  className="d-dark-mode-inline-block d-none"
                />
              </div>
              <span className="text-body">PHP</span>
            </div> */}
            {/* Swiper item */}
            <div className="swiper-slide">
              <div className="mb-3">
                <img
                  src="assets/img/landing/software-agency-3/technologies/angular-light.svg"
                  alt="Logo"
                  className="d-dark-mode-none d-inline-block"
                />
                <img
                  src="assets/img/landing/software-agency-3/technologies/angular-dark.svg"
                  alt="Logo"
                  className="d-dark-mode-inline-block d-none"
                />
              </div>
              <span className="text-body">Angular</span>
            </div>
            {/* Swiper item */}
            {/* <div className="swiper-slide">
              <div className="mb-3">
                <img
                  src="assets/img/landing/software-agency-3/technologies/python-light.svg"
                  alt="Logo"
                  className="d-dark-mode-none d-inline-block"
                />
                <img
                  src="assets/img/landing/software-agency-3/technologies/python-dark.svg"
                  alt="Logo"
                  className="d-dark-mode-inline-block d-none"
                />
              </div>
              <span className="text-body">Python</span>
            </div> */}
          </div>
          {/* Swiper pagination */}
          <div className="swiper-pagination position-static mt-4 pt-lg-3 pt-2" />
        </div>
      </section>
    );
  }
}

export default Technologies;
