import React, { FormEvent } from "react";
import Header from "./headers";
import Footer from "./Footer";
import emailjs from "emailjs-com";
interface ContactUsProps {}

interface ContactUsState {
  message: string;
  email: string;
  name: string;
}

export class ContactUs extends React.Component<ContactUsProps, ContactUsState> {
  state = {
    message: "",
    email: "",
    name: "",
  };
  componentDidMount(): void {
    const preloader = document.querySelector(".page-loading");
    preloader?.classList.remove("active");
    setTimeout(function () {
      preloader?.remove();
    }, 2000);
  }
  onSubmit(ev: FormEvent<HTMLFormElement>) {
    console.log(
      this.state.email,
      this.state.name,
      this.state.message,
      ev.currentTarget
    );
    ev.preventDefault();
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID || "",
        process.env.REACT_APP_EMAILJS_TEMPLETE_ID || "",
        {
          message: `${this.state.name}\n${this.state.email}\n${this.state.message}`,
        },
        "XoR3PnNSr5iweAwc8"
      )
      .then(
        () => {
          console.log("sent");
          this.setState({ name: "", email: "", message: "" });
          window.location.reload();
        },
        (err) => {
          console.log(err);
        }
      );
  }
  render() {
    return (
      <>
        <div className="page-loading active">
          <div className="page-loading-inner">
            <div className="page-spinner"></div>
            <span>Loading...</span>
          </div>
        </div>
        <main>
          <Header />
          <section
            className="bg-size-cover bg-position-bottom-center bg-repeat-0 py-5"
            style={{ backgroundImage: "url(assets/img/contacts/bg.svg)" }}
          >
            <div className="container pt-5 pb-lg-4 pb-xl-5">
              {/* Breadcrumb */}
              <nav className="pt-lg-4" aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <a href="/">
                      <i className="bx bx-home-alt fs-lg me-1" />
                      Home
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Contacts Us
                  </li>
                </ol>
              </nav>
              <div className="row pt-md-2 pt-lg-5 pb-2 pb-md-4">
                <div className="col-xxl-4 col-xl-5 col-lg-6 pt-3 mt-3">
                  <h1 className="h3 mb-2">Want to work with us?</h1>
                  <h2 className="display-1 text-gradient-primary pb-sm-2 pb-md-3 mb-3">
                    Let's talk!
                  </h2>
                  <div className="nav d-block lead pt-lg-5">
                    <a
                      href="mailto:sales@eastwooddigitaldynasty.com"
                      className="nav-link fw-normal text-decoration-underline p-0 mb-4"
                    >
                      sales@eastwooddigitaldynasty.com
                    </a>
                    <div className="text-nav">+1 (801) 396-5175</div>
                  </div>
                </div>
                <div className="col-lg-6 offset-xl-1 offset-xxl-2 pt-3 pt-md-4 pt-lg-3 mt-3">
                  <form
                    className="needs-validation"
                    noValidate
                    onSubmit={(ev) => this.onSubmit(ev)}
                  >
                    <div className="row g-4">
                      <div className="col-sm-6">
                        <label htmlFor="fn" className="form-label fs-base">
                          Full name
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          id="fn"
                          required
                          onChange={(ev) =>
                            this.setState({ name: ev.target.value })
                          }
                        />
                        <div className="invalid-feedback">
                          Please enter your full name!
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <label htmlFor="email" className="form-label fs-base">
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-control form-control-lg"
                          id="email"
                          required
                          onChange={(ev) =>
                            this.setState({ email: ev.target.value })
                          }
                        />
                        <div className="invalid-feedback">
                          Please provide a valid email address!
                        </div>
                      </div>
                      <div className="col-12 pb-2">
                        <label htmlFor="message" className="form-label fs-base">
                          Message
                        </label>
                        <textarea
                          className="form-control form-control-lg"
                          id="message"
                          rows={3}
                          required
                          defaultValue={""}
                          onChange={(ev) =>
                            this.setState({ message: ev.target.value })
                          }
                        />
                        <div className="invalid-feedback">
                          Please provide a valid message!
                        </div>
                      </div>
                      <div className="col-12">
                        <button
                          type="submit"
                          className="btn btn-lg btn-primary w-100 w-sm-auto"
                        >
                          Contact Us
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer page="Contact" />
      </>
    );
  }
}
export class ContactForm extends ContactUs {
  render() {
    return (
      <section
        className="bg-size-cover bg-position-bottom-center bg-repeat-0 py-5"
      >
        <div className="container pt-5 pb-lg-4 pb-xl-5">
          <div className="row pt-md-2 pt-lg-5 pb-2 pb-md-4">
            <div className="col-xxl-4 col-xl-5 col-lg-6 pt-3 mt-3">
              <h1 className="h3 mb-2">Want to work with us?</h1>
              <h2 className="display-1 text-gradient-primary pb-sm-2 pb-md-3 mb-3">
                Let's talk!
              </h2>
              <div className="nav d-block lead pt-lg-5">
                <a
                  href="mailto:sales@eastwooddigitaldynasty.com"
                  className="nav-link fw-normal text-decoration-underline p-0 mb-4"
                >
                  sales@eastwooddigitaldynasty.com
                </a>
                <div className="text-nav">+1 (801) 396-5175</div>
              </div>
            </div>
            <div className="col-lg-6 offset-xl-1 offset-xxl-2 pt-3 pt-md-4 pt-lg-3 mt-3">
              <form
                className="needs-validation"
                noValidate
                onSubmit={(ev) => this.onSubmit(ev)}
              >
                <div className="row g-4">
                  <div className="col-sm-6">
                    <label htmlFor="fn" className="form-label fs-base">
                      Full name
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="fn"
                      required
                      onChange={(ev) =>
                        this.setState({ name: ev.target.value })
                      }
                    />
                    <div className="invalid-feedback">
                      Please enter your full name!
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <label htmlFor="email" className="form-label fs-base">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control form-control-lg"
                      id="email"
                      required
                      onChange={(ev) =>
                        this.setState({ email: ev.target.value })
                      }
                    />
                    <div className="invalid-feedback">
                      Please provide a valid email address!
                    </div>
                  </div>
                  <div className="col-12 pb-2">
                    <label htmlFor="message" className="form-label fs-base">
                      Message
                    </label>
                    <textarea
                      className="form-control form-control-lg"
                      id="message"
                      rows={3}
                      required
                      defaultValue={""}
                      onChange={(ev) =>
                        this.setState({ message: ev.target.value })
                      }
                    />
                    <div className="invalid-feedback">
                      Please provide a valid message!
                    </div>
                  </div>
                  <div className="col-12">
                    <button
                      type="submit"
                      className="btn btn-lg btn-primary w-100 w-sm-auto"
                    >
                      Contact Us
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default ContactUs;
