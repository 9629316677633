import React from "react";
import Footer from "./Footer";
import Header from "./headers";
import { ContactForm } from "./ContactUs";
import DanielImg from "../assets/DanielEastwood.jpg";
interface AboutProps {}

interface AboutState {}

class About extends React.Component<AboutProps, AboutState> {
  state = {};
  render() {
    return (
      <section className="container my-5 py-lg-5 py-md-4 py-3">
        <div className="row gy-4 py-xl-3">
          <div className="col-md-6">
            <img
              src="assets/img/landing/software-agency-1/about-img.jpg"
              alt="Image"
              className="rounded-3"
            />
          </div>
          <div className="col-lg-5 col-md-6 offset-lg-1 d-flex">
            <div className="align-self-center ps-lg-0 ps-md-4">
              <h2 className="h1 mb-lg-4 mb-3">
                Award-Winning Software Company
              </h2>
              <p className="mb-4 pb-lg-3 fs-lg">
                Welcome to Eastwood Digital Dynasty, where innovation meets
                excellence in the world of web development! We are a passionate
                and dynamic startup dedicated to crafting cutting-edge digital
                solutions tailored to meet the unique needs of our clients.
              </p>

              <a href="/about" className="btn btn-lg btn-outline-primary">
                More about us
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

interface AboutPageProps {}

interface AboutPageState {}
export class AboutPage extends React.Component<AboutPageProps, AboutPageState> {
  state = {};
  componentDidMount(): void {
    const preloader = document.querySelector(".page-loading");
    preloader?.classList.remove("active");
    setTimeout(function () {
      preloader?.remove();
    }, 2000);
  }
  render() {
    return (
      <>
        {/* <!-- Page loading spinner --> */}
        <div className="page-loading active">
          <div className="page-loading-inner">
            <div className="page-spinner"></div>
            <span>Loading...</span>
          </div>
        </div>

        {/* <!-- Page wrapper htmlFor sticky footer -->
      <!-- Wraps everything except footer to push footer to the bottom of the page if there is little content --> */}
        <main className="page-wrapper">
          {/* <!-- Navbar -->
        <!-- Remove "navbar-sticky" class to make navigation bar scrollable with the page --> */}
          <Header />
          {/* <!-- Hero image --> */}
          <div
            className="jarallax d-none d-md-block"
            data-jarallax
            data-speed="0.35"
          >
            <span className="position-absolute top-0 start-0 w-100 h-100 bg-gradient-primary-translucent"></span>
            <div
              className="jarallax-img"
              style={{ backgroundImage: "url(../assets/img/about/cover.jpg)" }}
            ></div>
            <div
              className="d-none d-xxl-block"
              style={{ height: "700px" }}
            ></div>
            <div
              className="d-none d-md-block d-xxl-none"
              style={{ height: "550px" }}
            ></div>
          </div>
          {/* <!-- Breadcrumb --> */}
          <nav className="container py-4 mb-2 my-lg-3" aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">
                  <i className="bx bx-home-alt fs-lg me-1"></i>Home
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                About
              </li>
            </ol>
          </nav>
          {/* <!-- About company --> */}
          <section className="container pb-5 mb-md-2 mb-lg-4">
            <h1 className="border-bottom pb-4">Our Story</h1>
            <div className="row pt-2 pt-md-3">
              <div className="col-lg">
                <p className="fs-lg  mb-1 mb-lg-4">
                  Established with a vision to transform ideas into captivating
                  online experiences, Eastwood Digital Dynasty is a
                  collaborative endeavor of creative minds and tech enthusiasts.
                  Our journey began with a shared belief in the power of the web
                  to drive businesses forward and enhance user engagement.
                </p>
              </div>
            </div>
            <h1 className="border-bottom pb-4">What Sets Us Apart</h1>
            <div className="row pt-2 pt-md-3">
              <div className="col-lg">
                <p className="fs-lg  mb-1 mb-lg-4">
                  At Eastwood Digital Dynasty, we take pride in our commitment
                  to delivering not just websites, but digital masterpieces that
                  resonate with your brand identity. Our team of skilled
                  developers, designers, and strategists collaborate seamlessly
                  to bring your vision to life. We believe in staying ahead of
                  the curve, leveraging the latest technologies and industry
                  trends to ensure your online presence is always at the
                  forefront.
                </p>
              </div>
            </div>
            <h1 className="border-bottom pb-4">Our Services</h1>
            <div className="row pt-2 pt-md-3">
              <div className="col-lg">
                <ul>
                  <li>
                    <h5 style={{ display: "inline", marginRight: "10px" }}>
                      Static Website:
                    </h5>
                    We will create a static website to showcase any and all
                    information you want. This package comes with one static
                    webpage, Standard member support and one revision available.
                  </li>
                  <li>
                    <h5 style={{ display: "inline", marginRight: "10px" }}>
                      Dynamic Website:
                    </h5>
                    We will create a dynamic website to showcase any and all
                    information you want. This package comes with five to ten
                    dynamic webpages, faster member support, a database
                    connection to store data and up to four revisions available.
                  </li>
                  <li>
                    <h5 style={{ display: "inline", marginRight: "10px" }}>
                      Website Package:
                    </h5>
                    We will create a dynamic website to showcase any and all
                    information you want. This package comes with five to ten
                    dynamic webpages, fastest member support, a database
                    connection to store data and up to 30 revisions available.
                  </li>
                  <li>
                    <h5 style={{ display: "inline", marginRight: "10px" }}>
                      Additional Services available:
                    </h5>
                    Adding a contact form that adds email support, additional
                    revisions, additional pages, Website Hosting/Website Domain
                    name, and three month update package
                  </li>
                  <a
                    style={{ marginTop: "10px" }}
                    href="/pricing"
                    className="btn btn-light btn-lg shadow-secondary"
                  >
                    Compare Packages
                  </a>
                </ul>
                <p className="fs-lg  mb-1 mb-lg-4">
                  Whether you're a startup looking for a bold entrance into the
                  digital realm or an established business seeking a refresh, we
                  offer a comprehensive suite of web development services. From
                  responsive websites and e-commerce platforms to custom web
                  applications, our solutions are crafted with precision and an
                  eye for detail.
                </p>
              </div>
            </div>
            <h1 className="border-bottom pb-4">
              Why Choose Eastwood Digital Dynasty
            </h1>
            <div className="row pt-2 pt-md-3">
              <div className="col-lg">
                <ul>
                  <li>
                    <h5 style={{ display: "inline", marginRight: "10px" }}>
                      Tailored Solutions:
                    </h5>
                    We understand that one size doesn't fit all. Our solutions
                    are customized to align with your unique goals and
                    requirements.
                  </li>
                  <li>
                    <h5 style={{ display: "inline", marginRight: "10px" }}>
                      Collaborative Approach:
                    </h5>
                    We believe in transparent communication and collaboration.
                    Your ideas and feedback are integral to the development
                    process.
                  </li>
                  <li>
                    <h5 style={{ display: "inline", marginRight: "10px" }}>
                      Cutting-Edge Technology:
                    </h5>
                    Stay ahead of the competition with our use of the latest
                    technologies and development practices.
                  </li>
                  <li>
                    <h5 style={{ display: "inline", marginRight: "10px" }}>
                      Customer-Centric Focus:
                    </h5>
                    Your satisfaction is our priority. We are dedicated to
                    delivering projects on time and within budget.
                  </li>
                </ul>
              </div>
            </div>
            <h1 className="border-bottom pb-4">Let's Build Together</h1>
            <div className="row pt-2 pt-md-3">
              <div className="col-lg">
                <p className="fs-lg  mb-1 mb-lg-4">
                  Embark on a journey of digital transformation with Eastwood
                  Digital Dynasty. Whether you're starting from scratch or
                  looking to revamp your online presence, we're here to turn
                  your vision into reality. Explore the possibilities with us
                  and let's build something extraordinary together.
                </p>
              </div>
            </div>
            <h3>
              Welcome to Eastwood Digital Dynasty - where innovation and
              creativity converge for a digital future!
            </h3>
          </section>
          {/* <!-- Testimonials (Slider) --> */}
          {/* <section className="container pb-5 mb-2 mb-md-4 mb-xl-5">
            <div className="row">
              <div className="col-md-5 mb-4 mb-md-0">
                <div className="card justify-content-center bg-dark h-100 p-4 p-lg-5">
                  <div className="p-2">
                    <h3 className="display-4 text-primary mb-1">200+</h3>
                    <h2 className="text-light pb-5 mb-2">
                      Clients Already Served
                    </h2>
                    <a
                      href="#"
                      className="d-inline-flex align-items-center rounded-3 text-decoration-none py-3 px-4"
                      style={{ backgroundColor: "#3e4265" }}
                    >
                      <div className="pe-3">
                        <div className="fs-xs text-light text-uppercase opaciy-70 mb-2">
                          Reviewed on
                        </div>
                        <img
                          src="../assets/img/about/clutch-logo.svg"
                          width="97"
                          alt="Clutch"
                        />
                      </div>
                      <div className="ps-1">
                        <div className="text-nowrap mb-2">
                          <i className="bx bxs-star text-warning"></i>
                          <i className="bx bxs-star text-warning"></i>
                          <i className="bx bxs-star text-warning"></i>
                          <i className="bx bxs-star text-warning"></i>
                          <i className="bx bxs-star text-warning"></i>
                        </div>
                        <div className="text-light opacity-70">49 reviews</div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="card border-0 shadow-sm p-4 p-xxl-5">
                  <div className="d-flex justify-content-between pb-4 mb-2">
                    <span className="btn btn-icon btn-primary btn-lg shadow-primary pe-none">
                      <i className="bx bxs-quote-left"></i>
                    </span>
                    <div className="d-flex">
                      <button
                        type="button"
                        id="prev"
                        className="btn btn-prev btn-icon btn-sm me-2"
                        aria-label="Previous"
                      >
                        <i className="bx bx-chevron-left"></i>
                      </button>
                      <button
                        type="button"
                        id="next"
                        className="btn btn-next btn-icon btn-sm ms-2"
                        aria-label="Next"
                      >
                        <i className="bx bx-chevron-right"></i>
                      </button>
                    </div>
                  </div>

                  <div
                    className="swiper mx-0 mb-md-n2 mb-xxl-n3"
                    data-swiper-options='{
                  "spaceBetween": 24,
                  "loop": true,
                  "pagination": {
                    "el": ".swiper-pagination",
                    "clickable": true
                  },
                  "navigation": {
                    "prevEl": "#prev",
                    "nextEl": "#next"
                  }
                }'
                  >
                    <div className="swiper-wrapper">
                      <div
                        className="swiper-slide h-auto"
                        data-swiper-tab="#author-1"
                      >
                        <figure className="card h-100 position-relative border-0 bg-transparent">
                          <blockquote className="card-body p-0 mb-0">
                            <p className="fs-lg mb-0">
                              Dolor, a eget elementum, integer nulla volutpat,
                              nunc, sit. Quam iaculis varius mauris magna sem.
                              Egestas sed sed suscipit dolor faucibus dui
                              imperdiet at eget. Tincidunt imperdiet quis
                              hendrerit aliquam feugiat neque cras sed. Dictum
                              quam integer volutpat tellus, faucibus platea.
                              Pulvinar turpis proin faucibus at mauris. Sagittis
                              gravida vitae porta enim.
                            </p>
                          </blockquote>
                          <figcaption className="card-footer border-0 d-flex align-items-center w-100 pb-2">
                            <img
                              src="../assets/img/avatar/05.jpg"
                              width="48"
                              className="rounded-circle"
                              alt="Ralph Edwards"
                            />
                            <div className="ps-3">
                              <h5 className="fw-semibold lh-base mb-0">
                                Ralph Edwards
                              </h5>
                              <span className="fs-sm text-muted">
                                Head of Marketing at Lorem Company
                              </span>
                            </div>
                          </figcaption>
                        </figure>
                      </div>

                      <div
                        className="swiper-slide h-auto"
                        data-swiper-tab="#author-2"
                      >
                        <figure className="card h-100 position-relative border-0 bg-transparent">
                          <blockquote className="card-body p-0 mb-0">
                            <p className="fs-lg mb-0">
                              Mi semper risus ultricies orci pulvinar in at enim
                              orci. Quis facilisis nunc pellentesque in
                              ullamcorper sit. Lorem blandit arcu sapien,
                              senectus libero, amet dapibus cursus quam. Eget
                              pellentesque eu purus volutpat adipiscing
                              malesuada. Purus nisi, tortor vel lacus. Donec
                              diam molestie ultrices vitae eget pulvinar fames.
                              Velit lacus mi purus velit justo, amet.
                            </p>
                          </blockquote>
                          <figcaption className="card-footer border-0 d-flex align-items-center w-100 pb-2">
                            <img
                              src="../assets/img/avatar/06.jpg"
                              width="48"
                              className="rounded-circle"
                              alt="Annette Black"
                            />
                            <div className="ps-3">
                              <h5 className="fw-semibold lh-base mb-0">
                                Annette Black
                              </h5>
                              <span className="fs-sm text-muted">
                                Strategic Advisor at Ipsum Ltd.
                              </span>
                            </div>
                          </figcaption>
                        </figure>
                      </div>

                      <div
                        className="swiper-slide h-auto"
                        data-swiper-tab="#author-3"
                      >
                        <figure className="card h-100 position-relative border-0 bg-transparent">
                          <blockquote className="card-body p-0 mb-0">
                            <p className="fs-lg mb-0">
                              Ac at sed sit senectus massa. Massa ante amet
                              ultrices magna porta tempor. Aliquet diam in et
                              magna ultricies mi at. Lectus enim, vel enim
                              egestas nam pellentesque et leo. Elit mi faucibus
                              laoreet aliquam pellentesque sed aliquet integer
                              massa. Orci leo tortor ornare id mattis auctor
                              aliquam volutpat aliquet. Odio lectus viverra eu
                              blandit nunc malesuada vitae eleifend pulvinar.
                            </p>
                          </blockquote>
                          <figcaption className="card-footer border-0 d-flex align-items-center w-100 pb-2">
                            <img
                              src="../assets/img/avatar/01.jpg"
                              width="48"
                              className="rounded-circle"
                              alt="Darrell Steward"
                            />
                            <div className="ps-3">
                              <h5 className="fw-semibold lh-base mb-0">
                                Darrell Steward
                              </h5>
                              <span className="fs-sm text-muted">
                                Project Manager at Company Ltd.
                              </span>
                            </div>
                          </figcaption>
                        </figure>
                      </div>
                    </div>

                    <div className="swiper-pagination position-relative pt-3 mt-4"></div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          {/* <!-- Team (Slider) --> */}
          <section className="container-fluid pt-lg-2 pb-5 mb-2 mb-md-4 mb-lg-5">
            <h2 className="h1 text-center pb-md-1 mb-1 mb-sm-3">Our Leaders</h2>
            <div className="swiper mx-0 mb-md-n2 mb-xxl-n3">
              <div className="swiper-wrapper">
                {/* <!-- Item --> */}
                <div className="swiper-slide py-3">
                  <div className="flip-card">
                    <div className="flip-card-inner">
                      <div className="flip-card-front">
                        <img
                          src={DanielImg}
                          className="d-block rounded-circle mx-auto mb-3"
                          width="162"
                          alt="Daniel Eastwood"
                        />
                        <h5 className="fw-medium fs-lg mb-1">
                          Daniel Eastwood
                        </h5>
                        <p className="fs-sm mb-3">Lead Developer - CEO</p>
                      </div>
                      <div className="flip-card-back">
                        <div className="d-flex justify-content-center"style={{columnGap:'10px'}}>
                          <p>CEO & Lead Developer</p>
                          <a
                            href="https://www.linkedin.com/in/daniel-eastwood/"
                            className="btn btn-icon btn-outline-secondary btn-linkedin btn-sm me-2"
                            aria-label="LinkedIn"
                            target="_blank"
                          >
                            <i className="bx bxl-linkedin"></i>
                          </a>
                        </div>
                        <p>
                          I have been developing since 2017 with more of an
                          emphasis on web development starting in the summer of
                          2018, all during my time working on my undergrad. In
                          2020, I had three internships with 2 different
                          companies, focusing primarily on Angular and the scrum
                          environment, to get more real-life experience in the
                          development world. After graduating in December 2020,
                          I got a career at Fidelity Investments working on two
                          other teams, the first being a backend team focused on
                          Java development, the second being a frontend team
                          focusing on Angular and typescript development to
                          create tools for the rest of the company to use.
                          Around August 2022, I accepted an offer for a
                          different career at Snell & Wilmer LLP, working with
                          the development team to create applications for the
                          firm. These applications use React and Typescript with
                          a C# backend if needed and help the firm by providing
                          tools that make their jobs easier. I then started
                          earning my master's of science in information and comm
                          technology with a concentration in web design and
                          development during the autumn quarter of 2023.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- Item --> */}
                <div className="swiper-slide py-3">
                  <div className="flip-card">
                    <div className="flip-card-inner">
                      <div className="flip-card-front">
                        <img
                          src="../assests/DanielEastwood.jpg"
                          className="d-block rounded-circle mx-auto mb-3"
                          width="162"
                          alt="Kassandra Eastwood"
                        />
                        <h5 className="fw-medium fs-lg mb-1">
                          Kassandra Eastwood
                        </h5>
                        <p className="fs-sm mb-3">COO</p>
                      </div>
                      <div className="flip-card-back">
                        <h1>John Doe</h1>
                        <p>Architect & Engineer</p>
                        <p>We love that guy</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- Contact form --> */}
          <ContactForm />
        </main>

        {/* <!-- Footer --> */}
        <Footer page="main" />

        {/* <!-- Back to top button --> */}
        <a href="#top" className="btn-scroll-top" data-scroll>
          <span className="btn-scroll-top-tooltip text-muted fs-sm me-2">
            Top
          </span>
          <i className="btn-scroll-top-icon bx bx-chevron-up"></i>
        </a>
      </>
    );
  }
}

export default About;
