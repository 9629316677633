import * as React from "react";
import Header from "./headers";
import Footer from "./Footer";

export interface PricingProps {}

export interface PricingState {
  prices: {
    annual1: string;
    annual2: string;
    annual3: string;
  };
  statement: string;
}

class Pricing extends React.PureComponent<PricingProps, PricingState> {
  state = {
    prices: {
      annual1: "$500+",
      annual2: "$600+",
      annual3: "$800+",
    },
    statement: "Price subject to project size",
  };
  componentDidMount(): void {
    const preloader = document.querySelector(".page-loading");
    preloader?.classList.remove("active");
    setTimeout(function () {
      preloader?.remove();
    }, 2000);
  }
  render() {
    return (
      <>
        <div className="page-loading active">
          <div className="page-loading-inner">
            <div className="page-spinner"></div>
            <span>Loading...</span>
          </div>
        </div>

        {/* <!-- Page wrapper for sticky footer --> */}
        {/* <!-- Wraps everything except footer to push footer to the bottom of the page if there is little content --> */}
        <main className="page-wrapper">
          {/* <!-- Navbar --> */}
          {/* <!-- Remove "navbar-sticky" className to make navigation bar scrollable with the page --> */}
          <Header/>

          {/* <!-- Hero --> */}
          <section className="bg-dark py-4" data-bs-theme="dark">
            <div className="container pb-2 py-lg-3">
              {/* <!-- Breadcrumb --> */}
              <nav className="pb-4 mb-lg-3" aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center justify-content-lg-start mb-0">
                  <li className="breadcrumb-item">
                    <a href="index.html">
                      <i className="bx bx-home-alt fs-lg me-1"></i>Home
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Pricing
                  </li>
                </ol>
              </nav>
              <h1 className="text-center mb-0">Transparent Pricing for You</h1>
            </div>
            <div style={{ height: "300px" }}></div>
          </section>

          {/* <!-- Pricing plans --> */}
          <section
            className="container position-relative zindex-2"
            style={{ marginTop: "-300px" }}
          >
            <div className="price-switch-wrapper">
              <div className="table-responsive-lg">
                <div className="d-flex align-items-center pb-4">
                  {/* <!-- Pricing plan --> */}
                  <div
                    className="border rounded-3 rounded-end-0 shadow-sm me-n1"
                    style={{ width: "32%", minWidth: "16rem" }}
                  >
                    <div className="card bg-light h-100 border-0 border-end rounded-end-0 py-3 py-sm-4 py-lg-5">
                      <div className="card-body text-center">
                        <h3 className="mb-2">Static Website</h3>
                        <div className="fs-lg pb-4 mb-3">
                          A static webpage featuring up to date code and
                          practices
                        </div>
                        <div className="display-5 text-dark mb-1">
                          {this.state.prices.annual1}
                        </div>
                        <div className="text-muted mb-5">
                          {this.state.statement}
                        </div>
                      </div>
                      <div className="card-footer border-0 text-center pt-0 pb-4">
                        <a
                          href="/contact"
                          className="btn btn-outline-primary btn-lg"
                        >
                          Get started now
                        </a>
                      </div>
                    </div>
                  </div>

                  {/* <!-- Featurd pricing plan --> */}
                  <div
                    className="bg-primary position-relative rounded-3 shadow-primary shadow-dark-mode-none zindex-2 p-4"
                    style={{ width: "36%", minWidth: "18rem" }}
                  >
                    <div className="card bg-transparent border-light py-3 py-sm-4 py-lg-5">
                      <div className="card-body text-light text-center">
                        <h3 className="text-light mb-2">Dynamic Website</h3>
                        <div className="fs-lg opacity-70 pb-4 mb-3">
                          A dynamic website with connection to a database
                          featuring up to date code and practices
                        </div>
                        <div className="display-5 text-dark mb-1">
                          {this.state.prices.annual2}
                        </div>
                        <div className="opacity-50 mb-5">
                          {this.state.statement}
                        </div>
                      </div>
                      <div className="card-footer border-0 text-center pt-0 pb-4">
                        <a
                          href="/contact"
                          className="btn btn-light btn-lg shadow-secondary"
                        >
                          Get started now
                        </a>
                      </div>
                    </div>
                  </div>

                  {/* <!-- Pricing plan --> */}
                  <div
                    className="border rounded-3 rounded-start-0 shadow-sm"
                    style={{ width: "32%", minWidth: "16rem" }}
                  >
                    <div className="card bg-light h-100 border-0 rounded-start-0 py-3 py-sm-4 py-lg-5">
                      <div className="card-body text-center">
                        <h3 className="mb-2">Website package</h3>
                        <div className="fs-lg pb-4 mb-3">
                          A dynamic website with connection to a database
                          featuring up to date code and practices along with the
                          3 month update package
                        </div>
                        <div className="display-5 text-dark mb-1">
                          {this.state.prices.annual3}
                        </div>
                        <div className="text-muted mb-5">
                          {this.state.statement}
                        </div>
                      </div>
                      <div className="card-footer border-0 text-center pt-0 pb-4">
                        <a
                          href="/contact"
                          className="btn btn-outline-primary btn-lg"
                        >
                          Get started now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <!-- Plans comparison --> */}
          <section className="container py-5 my-md-3 my-lg-5">
            <div className="row align-items-center">
              <div className="col-md-4 text-center text-md-start">
                <h2 className="h1 mb-4">Plan Comparison</h2>
                <p className="fs-lg mb-md-0">
                  Choose the option that makes the most sense for your business.
                </p>
              </div>
              {/* Mobile Only */}
              <div className="col-md-8">
                <div className="row row-cols-1 row-cols-md-3">
                  <div className="col text-md-center pb-2 mb-4">
                    <h3 className="h5 mb-2 mb-md-3">Static Website</h3>
                    <ul className="d-md-none list-unstyled">
                      <li className="d-flex align-items-center p-2">
                        <span className="text-dark fw-medium">
                          Pages:&nbsp;
                        </span>
                        1
                      </li>
                      <li className="d-flex align-items-center p-2 bg-secondary">
                        <span className="text-dark fw-medium">
                          Member support via email or Phone:&nbsp;
                        </span>
                        Standard
                      </li>
                      <li className="d-flex align-items-center p-2">
                        <span className="text-dark fw-medium">
                          Database Connection:&nbsp;
                        </span>
                        <i className="bx bx-x lead text-muted"></i>
                      </li>
                      <li className="d-flex align-items-center p-2 bg-secondary">
                        <span className="text-dark fw-medium">
                          Revisions:&nbsp;
                        </span>
                        1
                      </li>
                      {/* <li className="d-flex align-items-center p-2">
                        <span className="text-dark fw-medium">
                          Projects:&nbsp;
                        </span>
                        4 projects
                      </li>
                      <li className="d-flex align-items-center p-2 bg-secondary">
                        <span className="text-dark fw-medium">
                          Comments and sharing:&nbsp;
                        </span>
                        <i className="bx bx-check lead text-primary"></i>
                      </li>
                      <li className="d-flex align-items-center p-2">
                        <span className="text-dark fw-medium">
                          Zoom integration:&nbsp;
                        </span>
                        <i className="bx bx-check lead text-primary"></i>
                      </li>
                      <li className="d-flex align-items-center p-2 bg-secondary">
                        <span className="text-dark fw-medium">
                          Ongoing strategy calls:&nbsp;
                        </span>
                        <i className="bx bx-x lead text-muted"></i>
                      </li>
                      <li className="d-flex align-items-center p-2">
                        <span className="text-dark fw-medium">
                          Analytics dashboard:&nbsp;
                        </span>
                        <i className="bx bx-check lead text-primary"></i>
                      </li>
                      <li className="d-flex align-items-center p-2 bg-secondary">
                        <span className="text-dark fw-medium">
                          Get custom integrations:&nbsp;
                        </span>
                        <i className="bx bx-x lead text-muted"></i>
                      </li> */}
                    </ul>
                  </div>
                  <div className="col text-md-center pb-2 mb-4">
                    <h3 className="h5 mb-2 mb-md-3">Dynamic Website</h3>
                    <ul className="d-md-none list-unstyled">
                      <li className="d-flex align-items-center p-2">
                        <span className="text-dark fw-medium">
                          Pages:&nbsp;
                        </span>
                        5 to 10
                      </li>
                      <li className="d-flex align-items-center p-2 bg-secondary">
                        <span className="text-dark fw-medium">
                          Member support via email or Phone:&nbsp;
                        </span>
                        3 hours
                      </li>
                      <li className="d-flex align-items-center p-2">
                        <span className="text-dark fw-medium">
                          Database Connection:&nbsp;
                        </span>
                        <i className="bx bx-check lead text-primary"></i>
                      </li>
                      <li className="d-flex align-items-center p-2 bg-secondary">
                        <span className="text-dark fw-medium">
                          Revisions:&nbsp;
                        </span>
                        4
                      </li>
                      {/* <li className="d-flex align-items-center p-2">
                        <span className="text-dark fw-medium">
                          Projects:&nbsp;
                        </span>
                        Unlimited
                      </li>
                      <li className="d-flex align-items-center p-2 bg-secondary">
                        <span className="text-dark fw-medium">
                          Comments and sharing:&nbsp;
                        </span>
                        <i className="bx bx-check lead text-primary"></i>
                      </li>
                      <li className="d-flex align-items-center p-2">
                        <span className="text-dark fw-medium">
                          Zoom integration:&nbsp;
                        </span>
                        <i className="bx bx-check lead text-primary"></i>
                      </li>
                      <li className="d-flex align-items-center p-2 bg-secondary">
                        <span className="text-dark fw-medium">
                          Ongoing strategy calls:&nbsp;
                        </span>
                        <i className="bx bx-x lead text-muted"></i>
                      </li>
                      <li className="d-flex align-items-center p-2">
                        <span className="text-dark fw-medium">
                          Analytics dashboard:&nbsp;
                        </span>
                        <i className="bx bx-check lead text-primary"></i>
                      </li>
                      <li className="d-flex align-items-center p-2 bg-secondary">
                        <span className="text-dark fw-medium">
                          Get custom integrations:&nbsp;
                        </span>
                        <i className="bx bx-check lead text-primary"></i>
                      </li> */}
                    </ul>
                  </div>
                  <div className="col text-md-center mb-3">
                    <h3 className="h5 mb-2 mb-md-3">Website package</h3>
                    <ul className="d-md-none list-unstyled">
                      <li className="d-flex align-items-center p-2">
                        <span className="text-dark fw-medium">
                          Pages:&nbsp;
                        </span>
                        5 to 10
                      </li>
                      <li className="d-flex align-items-center p-2 bg-secondary">
                        <span className="text-dark fw-medium">
                          Member support via email or Phone:&nbsp;
                        </span>
                        With highest priority
                      </li>
                      <li className="d-flex align-items-center p-2">
                        <span className="text-dark fw-medium">
                          Database Connection:&nbsp;
                        </span>
                        <i className="bx bx-check lead text-primary"></i>
                      </li>
                      <li className="d-flex align-items-center p-2 bg-secondary">
                        <span className="text-dark fw-medium">
                          Revisions:&nbsp;
                        </span>
                        30
                      </li>
                      {/* <li className="d-flex align-items-center p-2">
                        <span className="text-dark fw-medium">
                          Projects:&nbsp;
                        </span>
                        Unlimited
                      </li>
                      <li className="d-flex align-items-center p-2 bg-secondary">
                        <span className="text-dark fw-medium">
                          Comments and sharing:&nbsp;
                        </span>
                        <i className="bx bx-check lead text-primary"></i>
                      </li>
                      <li className="d-flex align-items-center p-2">
                        <span className="text-dark fw-medium">
                          Zoom integration:&nbsp;
                        </span>
                        <i className="bx bx-check lead text-primary"></i>
                      </li>
                      <li className="d-flex align-items-center p-2 bg-secondary">
                        <span className="text-dark fw-medium">
                          Ongoing strategy calls:&nbsp;
                        </span>
                        <i className="bx bx-check lead text-primary"></i>
                      </li>
                      <li className="d-flex align-items-center p-2">
                        <span className="text-dark fw-medium">
                          Analytics dashboard:&nbsp;
                        </span>
                        <i className="bx bx-check lead text-primary"></i>
                      </li>
                      <li className="d-flex align-items-center p-2 bg-secondary">
                        <span className="text-dark fw-medium">
                          Get custom integrations:&nbsp;
                        </span>
                        <i className="bx bx-check lead text-primary"></i>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-none d-md-block pt-5 mt-2 pb-4">
              <div className="row g-0">
                <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4">
                  Pages
                </div>
                <div className="col-md-8 text-center">
                  <div className="row row-cols-3 g-0">
                    <div className="col px-2 py-4">1</div>
                    <div className="col position-relative bg-primary px-2 py-4">
                      <span className="text-light opacity-80">5 to 10</span>
                      <span
                        className="position-absolute start-0 bottom-100 w-100 bg-primary rounded-3 rounded-bottom-0"
                        style={{ height: "2rem" }}
                      ></span>
                    </div>
                    <div className="col px-2 py-4">5 to 10</div>
                  </div>
                </div>
              </div>
              <div className="row g-0 bg-secondary rounded-3">
                <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4">
                  Member support via email or Phone
                </div>
                <div className="col-md-8 text-center">
                  <div className="row row-cols-3 g-0">
                    <div className="col px-2 py-4">Standard</div>
                    <div className="col bg-primary px-2 py-4">
                      <span className="text-light opacity-80">3 hours</span>
                    </div>
                    <div className="col px-2 py-4">With highest priority</div>
                  </div>
                </div>
              </div>
              <div className="row g-0">
                <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4">
                  Database Connection
                </div>
                <div className="col-md-8 text-center">
                  <div className="row row-cols-3 g-0">
                    <div className="col px-2 py-4">
                      <i className="bx bx-x lead text-muted"></i>
                    </div>
                    <div className="col bg-primary px-2 py-4">
                      <i className="bx bx-check lead text-light"></i>
                    </div>
                    <div className="col px-2 py-4">
                      <i className="bx bx-check lead text-primary"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row g-0 bg-secondary rounded-3">
                <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4">
                  Revisions
                </div>
                <div className="col-md-8 text-center">
                  <div className="row row-cols-3 g-0">
                    <div className="col px-2 py-4">1</div>
                    <div className="col bg-primary px-2 py-4">
                      <span className="text-light opacity-80">4</span>
                    </div>
                    <div className="col px-2 py-4">30</div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="container py-5 my-md-3 my-lg-5">
            <div className="row align-items-center">
              <div className="col-md-4 text-center text-md-start">
                <h2 className="h1 mb-4">Additional Addons</h2>
                <p className="fs-lg mb-md-0">
                  Choose the options that makes the most sense for your
                  business.
                </p>
              </div>
            </div>
            <div className=" d-md-block pt-5 mt-2 pb-4">
              <div className="row g-0">
                <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4">
                  Contact Form - email support
                </div>
                <div className="col-md-8 text-center">
                  <div className="row row-cols-3 g-0">
                    <div className="col px-2 py-4">$50 Per Month</div>
                    {/* <div className="col bg-primary px-2 py-4">
                      <span className="text-light opacity-80">$50</span>
                    </div>
                    <div className="col px-2 py-4">$50</div> */}
                  </div>
                </div>
              </div>
              <div className="row g-0 bg-secondary rounded-3">
                <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4">
                  Additional Revisions
                </div>
                <div className="col-md-8 text-center">
                  <div className="row row-cols-3 g-0">
                    <div className="col px-2 py-4">$50 per page revised</div>
                    {/* <div className="col bg-primary px-2 py-4">
                      <span className="text-light opacity-80">$50</span>
                    </div>
                    <div className="col px-2 py-4">$50</div> */}
                  </div>
                </div>
              </div>
              <div className="row g-0">
                <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4">
                  Additional Pages
                </div>
                <div className="col-md-8 text-center">
                  <div className="row row-cols-3 g-0">
                    <div className="col px-2 py-4">$50 per additional page</div>
                    {/* <div className="col bg-primary px-2 py-4">
                      <span className="text-light opacity-80">$50</span>
                    </div>
                    <div className="col px-2 py-4">$50</div> */}
                  </div>
                </div>
              </div>
              <div className="row g-0 bg-secondary rounded-3">
                <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4">
                  Website Hosting/Wesbite Domain Name
                </div>
                <div className="col-md-8 text-center">
                  <div className="row row-cols-3 g-0">
                    <div className="col px-2 py-4">$125 Per Month</div>
                    {/* <div className="col bg-primary px-2 py-4">
                      <span className="text-light opacity-80">$50</span>
                    </div>
                    <div className="col px-2 py-4">$50</div> */}
                  </div>
                </div>
              </div>
              <div className="row g-0">
                <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4">
                  3 Month Update Package
                </div>
                <div className="col-md-8 text-center">
                  <div className="row row-cols-3 g-0">
                    <div className="col px-2 py-4">$200+ every 3 months</div>
                    {/* <div className="col bg-primary px-2 py-4">
                      <span className="text-light opacity-80">$50</span>
                    </div>
                  */}
                    <div className="col px-2 py-4">
                      Upgrades to highest support priority
                    </div>
                    <div className="col px-2 py-4">
                      Provides 10 revisions per month for 3 months
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <!-- FAQ --> */}
          {/* <section className="bg-secondary py-5">
            <div className="container my-2 py-md-2 py-lg-5">
              <div className="row">
                <div className="col-xl-4 col-md-5 text-center text-md-start pb-2 pb-md-0 mb-4 mb-md-0">
                  <h2 className="pb-3 mb-1 mb-lg-2">
                    Any questions? <br className="d-none d-md-inline" />
                    Check out the FAQs
                  </h2>
                  <p className="fs-lg pb-3 mb-2 mb-lg-3">
                    Still have unanswered questions and need to get in touch?
                  </p>
                  <a href="contacts-v2.html" className="btn btn-primary">
                    Contact support
                  </a>
                </div>
                <div className="col-md-7 offset-xl-1">
                  <div className="accordion" id="faq">
                    <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                      <h2 className="accordion-header" id="q1-heading">
                        <button
                          className="accordion-button shadow-none rounded-3"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#q1"
                          aria-expanded="true"
                          aria-controls="q1"
                        >
                          What if I exceed my Free or paid plan limits?
                        </button>
                      </h2>
                      <div
                        id="q1"
                        className="accordion-collapse collapse show"
                        aria-labelledby="q1-heading"
                        data-bs-parent="#faq"
                      >
                        <div className="accordion-body fs-sm pt-0">
                          <p>
                            Nunc duis id aenean gravida tincidunt eu, tempor
                            ullamcorper. Viverra aliquam arcu, viverra et,
                            cursus. Aliquet pretium cursus adipiscing gravida et
                            consequat lobortis arcu velit. Nibh pharetra
                            fermentum duis accumsan lectus non. Massa cursus
                            molestie lorem scelerisque pellentesque. Nisi, enim,
                            arcu purus gravida adipiscing euismod montes, duis
                            egestas. Vehicula eu etiam quam tristique tincidunt
                            suspendisse ut consequat.
                          </p>
                          <p className="mb-0">
                            Ornare senectus fusce dignissim ut. Integer
                            consequat in eu tortor, faucibus et lacinia posuere.
                            Turpis sit viverra lorem suspendisse lacus aliquam
                            auctor vulputate. Quis egestas aliquam nunc purus
                            lacus, elit leo elit facilisi. Dignissim amet
                            adipiscing massa integer.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                      <h2 className="accordion-header" id="q2-heading">
                        <button
                          className="accordion-button shadow-none rounded-3 collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#q2"
                          aria-expanded="false"
                          aria-controls="q2"
                        >
                          How do I know which plan is right for me?
                        </button>
                      </h2>
                      <div
                        id="q2"
                        className="accordion-collapse collapse"
                        aria-labelledby="q2-heading"
                        data-bs-parent="#faq"
                      >
                        <div className="accordion-body fs-sm pt-0">
                          <p>
                            Nunc duis id aenean gravida tincidunt eu, tempor
                            ullamcorper. Viverra aliquam arcu, viverra et,
                            cursus. Aliquet pretium cursus adipiscing gravida et
                            consequat lobortis arcu velit. Nibh pharetra
                            fermentum duis accumsan lectus non. Massa cursus
                            molestie lorem scelerisque pellentesque. Nisi, enim,
                            arcu purus gravida adipiscing euismod montes, duis
                            egestas. Vehicula eu etiam quam tristique tincidunt
                            suspendisse ut consequat.
                          </p>
                          <p className="mb-0">
                            Ornare senectus fusce dignissim ut. Integer
                            consequat in eu tortor, faucibus et lacinia posuere.
                            Turpis sit viverra lorem suspendisse lacus aliquam
                            auctor vulputate. Quis egestas aliquam nunc purus
                            lacus, elit leo elit facilisi. Dignissim amet
                            adipiscing massa integer.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                      <h2 className="accordion-header" id="q3-heading">
                        <button
                          className="accordion-button shadow-none rounded-3 collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#q3"
                          aria-expanded="false"
                          aria-controls="q3"
                        >
                          Are there any long-term commitments or cancellation
                          fees?
                        </button>
                      </h2>
                      <div
                        id="q3"
                        className="accordion-collapse collapse"
                        aria-labelledby="q3-heading"
                        data-bs-parent="#faq"
                      >
                        <div className="accordion-body fs-sm pt-0">
                          <p>
                            Nunc duis id aenean gravida tincidunt eu, tempor
                            ullamcorper. Viverra aliquam arcu, viverra et,
                            cursus. Aliquet pretium cursus adipiscing gravida et
                            consequat lobortis arcu velit. Nibh pharetra
                            fermentum duis accumsan lectus non. Massa cursus
                            molestie lorem scelerisque pellentesque. Nisi, enim,
                            arcu purus gravida adipiscing euismod montes, duis
                            egestas. Vehicula eu etiam quam tristique tincidunt
                            suspendisse ut consequat.
                          </p>
                          <p className="mb-0">
                            Ornare senectus fusce dignissim ut. Integer
                            consequat in eu tortor, faucibus et lacinia posuere.
                            Turpis sit viverra lorem suspendisse lacus aliquam
                            auctor vulputate. Quis egestas aliquam nunc purus
                            lacus, elit leo elit facilisi. Dignissim amet
                            adipiscing massa integer.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                      <h2 className="accordion-header" id="q4-heading">
                        <button
                          className="accordion-button shadow-none rounded-3 collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#q4"
                          aria-expanded="false"
                          aria-controls="q4"
                        >
                          How do I cancel my monthly subscription?
                        </button>
                      </h2>
                      <div
                        id="q4"
                        className="accordion-collapse collapse"
                        aria-labelledby="q4-heading"
                        data-bs-parent="#faq"
                      >
                        <div className="accordion-body fs-sm pt-0">
                          <p>
                            Nunc duis id aenean gravida tincidunt eu, tempor
                            ullamcorper. Viverra aliquam arcu, viverra et,
                            cursus. Aliquet pretium cursus adipiscing gravida et
                            consequat lobortis arcu velit. Nibh pharetra
                            fermentum duis accumsan lectus non. Massa cursus
                            molestie lorem scelerisque pellentesque. Nisi, enim,
                            arcu purus gravida adipiscing euismod montes, duis
                            egestas. Vehicula eu etiam quam tristique tincidunt
                            suspendisse ut consequat.
                          </p>
                          <p className="mb-0">
                            Ornare senectus fusce dignissim ut. Integer
                            consequat in eu tortor, faucibus et lacinia posuere.
                            Turpis sit viverra lorem suspendisse lacus aliquam
                            auctor vulputate. Quis egestas aliquam nunc purus
                            lacus, elit leo elit facilisi. Dignissim amet
                            adipiscing massa integer.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                      <h2 className="accordion-header" id="q5-heading">
                        <button
                          className="accordion-button shadow-none rounded-3 collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#q5"
                          aria-expanded="false"
                          aria-controls="q5"
                        >
                          What happens if I upgrade or downgrade a plan
                          mid-period?
                        </button>
                      </h2>
                      <div
                        id="q5"
                        className="accordion-collapse collapse"
                        aria-labelledby="q5-heading"
                        data-bs-parent="#faq"
                      >
                        <div className="accordion-body fs-sm pt-0">
                          <p>
                            Nunc duis id aenean gravida tincidunt eu, tempor
                            ullamcorper. Viverra aliquam arcu, viverra et,
                            cursus. Aliquet pretium cursus adipiscing gravida et
                            consequat lobortis arcu velit. Nibh pharetra
                            fermentum duis accumsan lectus non. Massa cursus
                            molestie lorem scelerisque pellentesque. Nisi, enim,
                            arcu purus gravida adipiscing euismod montes, duis
                            egestas. Vehicula eu etiam quam tristique tincidunt
                            suspendisse ut consequat.
                          </p>
                          <p className="mb-0">
                            Ornare senectus fusce dignissim ut. Integer
                            consequat in eu tortor, faucibus et lacinia posuere.
                            Turpis sit viverra lorem suspendisse lacus aliquam
                            auctor vulputate. Quis egestas aliquam nunc purus
                            lacus, elit leo elit facilisi. Dignissim amet
                            adipiscing massa integer.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item border-0 rounded-3 shadow-sm">
                      <h2 className="accordion-header" id="q6-heading">
                        <button
                          className="accordion-button shadow-none rounded-3 collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#q6"
                          aria-expanded="false"
                          aria-controls="q6"
                        >
                          What kind of support do I get?
                        </button>
                      </h2>
                      <div
                        id="q6"
                        className="accordion-collapse collapse"
                        aria-labelledby="q6-heading"
                        data-bs-parent="#faq"
                      >
                        <div className="accordion-body fs-sm pt-0">
                          <p>
                            Nunc duis id aenean gravida tincidunt eu, tempor
                            ullamcorper. Viverra aliquam arcu, viverra et,
                            cursus. Aliquet pretium cursus adipiscing gravida et
                            consequat lobortis arcu velit. Nibh pharetra
                            fermentum duis accumsan lectus non. Massa cursus
                            molestie lorem scelerisque pellentesque. Nisi, enim,
                            arcu purus gravida adipiscing euismod montes, duis
                            egestas. Vehicula eu etiam quam tristique tincidunt
                            suspendisse ut consequat.
                          </p>
                          <p className="mb-0">
                            Ornare senectus fusce dignissim ut. Integer
                            consequat in eu tortor, faucibus et lacinia posuere.
                            Turpis sit viverra lorem suspendisse lacus aliquam
                            auctor vulputate. Quis egestas aliquam nunc purus
                            lacus, elit leo elit facilisi. Dignissim amet
                            adipiscing massa integer.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
        </main>

        {/* <!-- Footer --> */}
        <Footer page="main" />
      </>
    );
  }
}

export default Pricing;
