import React from "react";

interface FooterProps {
  page: string;
}

interface FooterState {}

class Footer extends React.Component<FooterProps, FooterState> {
  state = {};
  render() {
    switch (this.props.page) {
      case "main":
        return (
          <footer className="footer position-relative bg-dark pt-5 pb-4 pb-lg-5">
            <span className="position-absolute top-0 start-0 d-dark-mode-block d-none w-100 h-100 bg-secondary opacity-75" />
            <div
              className="position-relative container pt-lg-4"
              data-bs-theme="dark"
            >
              <div className="row pb-5">
                <div className="col-lg-4 col-md-6">
                  <div className="navbar-brand text-dark p-0 me-0 mb-3 mb-lg-4">
                    <img
                      src="assets/img/EastwoodDigitalDynastyLrg.png"
                      width="47"
                      alt="Silicon"
                    />
                    Eastwood Digital Dynasty
                  </div>
                  <p className="fs-sm text-light opacity-70 pb-lg-3 mb-4">
                    This website was created using a combination of a templete
                    purchased off of bootstrap and react.
                  </p>
                </div>
                <div className="col-xl-6 col-lg-7 col-md-5 offset-xl-2 offset-md-1 pt-4 pt-md-1 pt-lg-0">
                  <div id="footer-links" className="row">
                    <div className="col-lg-4">
                      <h6 className="mb-2">
                        <a
                          href="#useful-links"
                          className="d-block text-dark dropdown-toggle d-lg-none py-2"
                          data-bs-toggle="collapse"
                        >
                          Useful Links
                        </a>
                      </h6>
                      <div
                        id="useful-links"
                        className="collapse d-lg-block"
                        data-bs-parent="#footer-links"
                      >
                        <ul className="nav flex-column pb-lg-1 mb-lg-3">
                          <li className="nav-item">
                            <a
                              href="/"
                              className="nav-link d-inline-block px-0 pt-1 pb-2"
                            >
                              Home
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              href="/pricing"
                              className="nav-link d-inline-block px-0 pt-1 pb-2"
                            >
                              Pricing
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              href="/portfolio"
                              className="nav-link d-inline-block px-0 pt-1 pb-2"
                            >
                              Our Clients
                            </a>
                          </li>
                        </ul>
                        <ul className="nav flex-column mb-2 mb-lg-0">
                          <li className="nav-item">
                            <a
                              href="#"
                              className="nav-link d-inline-block px-0 pt-1 pb-2"
                            >
                              Terms &amp; Conditions
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              href="#"
                              className="nav-link d-inline-block px-0 pt-1 pb-2"
                            >
                              Privacy Policy
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* <div className="col-xl-4 col-lg-3">
                      <h6 className="mb-2">
                        <a
                          href="#social-links"
                          className="d-block text-dark dropdown-toggle d-lg-none py-2"
                          data-bs-toggle="collapse"
                        >
                          Socials
                        </a>
                      </h6>
                      <div
                        id="social-links"
                        className="collapse d-lg-block"
                        data-bs-parent="#footer-links"
                      >
                        <ul className="nav flex-column mb-2 mb-lg-0">
                          <li className="nav-item">
                            <a
                              href="#"
                              className="nav-link d-inline-block px-0 pt-1 pb-2"
                            >
                              Facebook
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              href="#"
                              className="nav-link d-inline-block px-0 pt-1 pb-2"
                            >
                              LinkedIn
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              href="#"
                              className="nav-link d-inline-block px-0 pt-1 pb-2"
                            >
                              Twitter
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              href="#"
                              className="nav-link d-inline-block px-0 pt-1 pb-2"
                            >
                              Instagram
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div> */}
                    <div className="col-xl-4 col-lg-5 pt-2 pt-lg-0">
                      <h6 className="mb-2">Contact Us</h6>
                      <a
                        href="mailto:sales@eastwooddigitaldynasty.com"
                        className="fw-medium"
                      >
                        sales@eastwooddigitaldynasty.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <p className="nav d-block fs-xs text-center text-md-start pb-2 pb-lg-0 mb-0">
                <span className="text-light opacity-50">
                  © All rights reserved. Templete created by{" "}
                </span>
                <a
                  className="nav-link d-inline-block p-0"
                  href="https://createx.studio/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Createx Studio
                </a>
              </p>
            </div>
          </footer>
        );
      case "Contact":
        return (
          <footer
            className="footer bg-dark pt-5 pb-4 pb-lg-5"
            data-bs-theme="dark"
          >
            <div className="container pt-lg-4">
              <div className="row pb-5">
                <div className="col-lg-4 col-md-6">
                  <div className="navbar-brand text-dark p-0 me-0 mb-3 mb-lg-4">
                    <img
                      src="assets/img/EastwoodDigitalDynastyLrg.png"
                      width="47"
                      alt="Silicon"
                    />
                    Eastwood Digital Dynasty
                  </div>
                  <p className="fs-sm text-light opacity-70 pb-lg-3 mb-4">
                    This website was created using a combination of a templete
                    purchased off of bootstrap and react.
                  </p>
                </div>
                <div className="col-xl-6 col-lg-7 col-md-5 offset-xl-2 offset-md-1 pt-4 pt-md-1 pt-lg-0">
                  <div id="footer-links" className="row">
                    <div className="col-lg-4">
                      <h6 className="mb-2">
                        <a
                          href="#useful-links"
                          className="d-block text-dark dropdown-toggle d-lg-none py-2"
                          data-bs-toggle="collapse"
                        >
                          Useful Links
                        </a>
                      </h6>
                      <div
                        id="useful-links"
                        className="collapse d-lg-block"
                        data-bs-parent="#footer-links"
                      >
                        <ul className="nav flex-column pb-lg-1 mb-lg-3">
                          <li className="nav-item">
                            <a
                              href="/"
                              className="nav-link d-inline-block px-0 pt-1 pb-2"
                            >
                              Home
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              href="/pricing"
                              className="nav-link d-inline-block px-0 pt-1 pb-2"
                            >
                              Pricing
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              href="/portfolio"
                              className="nav-link d-inline-block px-0 pt-1 pb-2"
                            >
                              Our Clients
                            </a>
                          </li>
                        </ul>
                        <ul className="nav flex-column mb-2 mb-lg-0">
                          <li className="nav-item">
                            <a
                              href="#"
                              className="nav-link d-inline-block px-0 pt-1 pb-2"
                            >
                              Terms &amp; Conditions
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              href="#"
                              className="nav-link d-inline-block px-0 pt-1 pb-2"
                            >
                              Privacy Policy
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* <div className="col-xl-4 col-lg-3">
                      <h6 className="mb-2">
                        <a
                          href="#social-links"
                          className="d-block text-dark dropdown-toggle d-lg-none py-2"
                          data-bs-toggle="collapse"
                        >
                          Socials
                        </a>
                      </h6>
                      <div
                        id="social-links"
                        className="collapse d-lg-block"
                        data-bs-parent="#footer-links"
                      >
                        <ul className="nav flex-column mb-2 mb-lg-0">
                          <li className="nav-item">
                            <a
                              href="#"
                              className="nav-link d-inline-block px-0 pt-1 pb-2"
                            >
                              Facebook
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              href="#"
                              className="nav-link d-inline-block px-0 pt-1 pb-2"
                            >
                              LinkedIn
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              href="#"
                              className="nav-link d-inline-block px-0 pt-1 pb-2"
                            >
                              Twitter
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              href="#"
                              className="nav-link d-inline-block px-0 pt-1 pb-2"
                            >
                              Instagram
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div> */}
                    <div className="col-xl-4 col-lg-5 pt-2 pt-lg-0">
                      <h6 className="mb-2">Contact Us</h6>
                      <a
                        href="mailto:sales@eastwooddigitaldynasty.com"
                        className="fw-medium"
                      >
                        sales@eastwooddigitaldynasty.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <p className="nav d-block fs-xs text-center text-md-start pb-2 pb-lg-0 mb-0">
                <span className="text-light opacity-50">
                  © All rights reserved. Templete created by{" "}
                </span>
                <a
                  className="nav-link d-inline-block p-0"
                  href="https://createx.studio/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Createx Studio
                </a>
              </p>
            </div>
          </footer>
        );
    }
  }
}

export default Footer;
