import React from "react";
import Solutions from "../components/Solutions";
import Technologies from "../components/Technologies";
import Benefits from "../components/Benefits";
import Footer from "../components/Footer";
import Headers from "../components/headers";
import About from "../components/About";
import Hero from "../components/Hero";
interface HomeProps {}

interface HomeState {}

class Home extends React.Component<HomeProps, HomeState> {
  state = {};
  componentDidMount(): void {
    const preloader = document.querySelector(".page-loading");
    preloader?.classList.remove("active");
    setTimeout(function () {
      preloader?.remove();
    }, 2000);
  }
  render() {
    return (
      <>
        <div className="page-loading active">
          <div className="page-loading-inner">
            <div className="page-spinner" />
            <span>Loading...</span>
          </div>
        </div>
        <main>
          <Headers />
          <Hero />
          <About />
          <Solutions />
          <Technologies />
          <Benefits />
        </main>
        <Footer page="main" />

        {/* Back to top button */}
        <a href="#top" className="btn-scroll-top" data-scroll="">
          <span className="btn-scroll-top-tooltip text-muted fs-sm me-2">
            Top
          </span>
          <i className="btn-scroll-top-icon bx bx-chevron-up" />
        </a>
      </>
    );
  }
}

export default Home;
