import React from "react";

interface HeaderProps {}

interface HeaderState {}

class Header extends React.Component<HeaderProps, HeaderState> {
  state = {};
  render() {
    return (
      <header className="header navbar navbar-expand-lg bg-light navbar-sticky fixed-top">
        <div className="container px-3">
          <a href="/" className="navbar-brand pe-3">
            <img
              src="assets/img/EastwoodDigitalDynastyLrg.png"
              width={47}
              alt="Eastwood Digital Dynasty"
            />
            Eastwood Digital Dynasty
          </a>
          <div id="navbarNav" className="offcanvas offcanvas-end bg-dark">
            <div className="offcanvas-header border-bottom border-light">
              <h5 className="offcanvas-title text-white">Menu</h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              />
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item dropdown">
                  <div
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                    style={{ cursor: "pointer" }}
                  >
                    Pages
                  </div>

                  <div className="dropdown-menu dropdown-menu-dark">
                    <div className="d-lg-flex pt-lg-3">
                      <div className="mega-dropdown-column">
                        {/* <h6 className="px-3 mb-2">About</h6> */}
                        <ul className="list-unstyled mb-3">
                          <li>
                            <a href="about" className="dropdown-item py-1">
                              About
                            </a>
                          </li>
                        </ul>
                        {/* <h6 className="px-3 mb-2">Portfolio</h6> */}
                        <ul className="list-unstyled mb-3">
                          <li>
                            <a href="portfolio" className="dropdown-item py-1">
                              Project we've completed
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div className="mega-dropdown-column">
                        {/* <h6 className="text-light px-3 mb-2">Pricing</h6> */}
                        <ul className="list-unstyled mb-3">
                          <li>
                            <a href="/pricing" className="dropdown-item py-1">
                              Pricing Page
                            </a>
                          </li>
                        </ul>
                        {/* <h6 className="px-3 mb-2">Contacts</h6> */}
                        <ul className="list-unstyled mb-3">
                          <li>
                            <a href="/contact" className="dropdown-item py-1">
                              Contact Us
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                {/* <li className="nav-item dropdown">
                  <div
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                    style={{ cursor: "pointer" }}
                  >
                    Account
                  </div>
                  <ul className="dropdown-menu dropdown-menu-dark">
                    <li>
                      <a
                        href="account-details.html"
                        className="dropdown-item"
                      >
                        Account Details
                      </a>
                    </li>
                    <li>
                      <a
                        href="account-security.html"
                        className="dropdown-item"
                      >
                        Security
                      </a>
                    </li>
                    <li>
                      <a
                        href="account-notifications.html"
                        className="dropdown-item"
                      >
                        Notifications
                      </a>
                    </li>
                    <li>
                      <a
                        href="account-messages.html"
                        className="dropdown-item"
                      >
                        Messages
                      </a>
                    </li>
                    <li>
                      <a
                        href="account-saved-items.html"
                        className="dropdown-item"
                      >
                        Saved Items
                      </a>
                    </li>
                    <li>
                      <a
                        href="account-collections.html"
                        className="dropdown-item"
                      >
                        My Collections
                      </a>
                    </li>
                    <li>
                      <a
                        href="account-payment.html"
                        className="dropdown-item"
                      >
                        Payment Details
                      </a>
                    </li>
                    <li>
                      <a
                        href="account-signin.html"
                        className="dropdown-item"
                      >
                        Sign In
                      </a>
                    </li>
                    <li>
                      <a
                        href="account-signup.html"
                        className="dropdown-item"
                      >
                        Sign Up
                      </a>
                    </li>
                  </ul>
                </li> */}
              </ul>
            </div>
          </div>
          <div
            className="form-check form-switch mode-switch pe-lg-1 ms-auto me-4"
            data-bs-toggle="mode"
          >
            <input
              type="checkbox"
              className="form-check-input"
              id="theme-mode"
            />
            <label
              className="form-check-label d-none d-sm-block"
              htmlFor="theme-mode"
            >
              Light
            </label>
            <label
              className="form-check-label d-none d-sm-block"
              htmlFor="theme-mode"
            >
              Dark
            </label>
          </div>
          <button
            type="button"
            className="navbar-toggler"
            data-bs-toggle="offcanvas"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
      </header>
    );
  }
}

export default Header;
