import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import MainHome from "./components/MainHome";
import { AboutPage } from "./components/About";
import { PortfolioPage } from "./components/Project";
import Pricing from "./components/Pricing";
import ErrorPage from "./components/404";
import ContactUs from "./components/ContactUs";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const router = createBrowserRouter([
  {
    path: "/",
    element: <MainHome></MainHome>,
  },
  {
    path: "about",
    element: <AboutPage />,
  },
  {
    path: "portfolio",
    element: <PortfolioPage />,
  },
  {
    path: "pricing",
    element: <Pricing />,
  },
  {
    path: "contact",
    element: <ContactUs />,
  },
  { path: "*", element: <ErrorPage /> },
  // {
  //   path: "blog",
  //   element: <BlogList />,
  // },
]);
root.render(
  <React.StrictMode>
    <RouterProvider router={router}></RouterProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
