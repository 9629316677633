import React from "react";
interface BenefitsProps {}

interface BenefitsState {}

class Benefits extends React.Component<BenefitsProps, BenefitsState> {
  state = {};
  render() {
    return (
      <section className="container mb-lg-5 mb-4 pb-3">
        <h2 className="h1 mb-lg-5 mb-4 pb-lg-0 pb-md-2 text-center">
          Our benefits
        </h2>
        {/* Swiper slider */}
        <div
          className="swiper mb-xl-3 text-sm-start text-center"
          data-swiper-options='{
  "spaceBetween": 24,
  "autoplay": true,
  "breakpoints": {
    "0": {
      "slidesPerView": 1
    },
    "500": {
      "slidesPerView": 2
    },
    "768": {
      "slidesPerView": 3
    },
    "1200": {
      "slidesPerView": 4
    }
  },
  "pagination": {
    "el": ".swiper-pagination",
    "clickable": true
  }
}'
        >
          <div className="swiper-wrapper">
            {/* Swiper item */}
            <div className="swiper-slide">
              <div
                className="mx-sm-0 mx-auto"
                style={{ maxWidth: "19.125rem" }}
              >
                <h4 className="h5 mb-3">Template in 24 hrs</h4>
                <p className="mb-0">
                  A template will be provided after all information is provieded
                  with what the website could look like depending on what the
                  user wants.
                </p>
              </div>
            </div>
            {/* Swiper item */}
            <div className="swiper-slide">
              <div
                className="mx-sm-0 mx-auto"
                style={{ maxWidth: "19.125rem" }}
              >
                <h4 className="h5 mb-3">MVP within two weeks</h4>
                <p className="mb-0">
                  Purus netus eget facilisis morbi pellentesque. Fermentum mi
                  sit a mi ebentot pellentesque.
                </p>
              </div>
            </div>
            {/* Swiper item */}
            <div className="swiper-slide">
              <div
                className="mx-sm-0 mx-auto"
                style={{ maxWidth: "19.125rem" }}
              >
                <h4 className="h5 mb-3">Kickstart development</h4>
                <p className="mb-0">
                  Purus netus eget facilisis morbi pellentesque. Fermentum mi
                  sit a mi ebentot pellentesque.
                </p>
              </div>
            </div>
            {/* Swiper item */}
            <div className="swiper-slide">
              <div
                className="mx-sm-0 mx-auto"
                style={{ maxWidth: "19.125rem" }}
              >
                <h4 className="h5 mb-3">Tech support</h4>
                <p className="mb-0">
                  Support will be provieded via email or phone.
                </p>
              </div>
            </div>
          </div>
          {/* Swiper pagination */}
          <div className="swiper-pagination position-static mt-4 pt-lg-3 pt-2" />
        </div>
      </section>
    );
  }
}

export default Benefits;
